<template>
  <div class="email-accessories">
    <VueTable
      :items="items"
      :headers="headers"
      :show-row-actions="true"
      @load-more="loadMoreItems"
      :show-select="false"
      icon="mdi-alpha-e-box-outline"
      :title="table_title"
      :key="componentKey"
      :no-more-data="!has_more_items"
      empty-text="No templates yet"
      :loading="loading_items"
    >
      <template v-slot:header-toolbar>
        <v-row dense>
          <v-spacer />
          <v-btn
            outlined
            class="text-none"
            text
            @click="
              $router.push({ name: 'campaigns', params: { type: 'email' } })
            "
          >
            <v-icon left>mdi-arrow-left-bold-circle-outline</v-icon>
            Back
          </v-btn>
          <v-btn
            dark
            color="primary"
            :disabled="btnloading"
            class="text-none ml-1"
            @click="redirectBuild"
          >
            <v-icon left>mdi-plus-circle-outline</v-icon>
            New
          </v-btn>
          <v-btn
            outlined
            class="text-none ml-1"
            text
            @click="
              $router.push({
                name: 'email-accessories',
                params: {
                  type: type === 'template' ? 'signatures' : 'templates',
                },
              })
            "
          >
            <v-icon left>mdi-arrow-right-bold-circle-outline</v-icon>
            Email {{ type | ucwords }}s
          </v-btn>
        </v-row>
      </template>
      <template v-slot:row-slot="{ item }">
        <td>{{ item.name | ucwords }}</td>
        <td class="text-center">{{ item.type | ucwords }}</td>
        <td class="text-center">{{ item.created_at | format("lll") }}</td>
        <ActionDropdown
          :item="item"
          :has-edit="true"
          :has-delete="true"
          :has-view="true"
          @view="action_view_dialog(true, item)"
          @delete="action_confirm_dialog(true, item)"
          @edit="redirectEdit(item)"
        >
        </ActionDropdown>
      </template>
    </VueTable>
    <DeleteDialog
      :open-dialog.sync="confirm_dialog"
      :title="`Delete Email ${type}`"
      :text-content="`Are you sure you want to delete this email ${type}?`"
      @delete="handleDeleteEmailAccesory"
      :delete-button-text="`Yes! Delete Email ${type}`"
    ></DeleteDialog>
  </div>
</template>

<script>
import VueTable from "@/common/VueTable/VueTable.vue";
import ActionDropdown from "@/common/VueTable/ActionDropdown.vue";
import DeleteDialog from "@/common/DeleteDialog.vue";
import { fetch_services } from "@/services/fetch/fetch_services";
export default {
  mixins: [fetch_services],
  components: {
    VueTable,
    ActionDropdown,
    DeleteDialog,
  },
  data: () => ({
    btnloading: false,
    componentKey: 0,
    headers: [
      {
        text: "Name",
        value: "destination_email",
        sortable: true,
        align: "left",
      },
      {
        text: "Type",
        value: "type",
        sortable: true,
        align: "center",
        width: 200,
      },
      {
        text: "Created",
        value: "created_at",
        sortable: true,
        align: "center",
        width: 200,
      },
      {
        text: "Action",
        value: "action",
        sortable: false,
        align: "center",
        width: 40,
      },
    ],
  }),
  created() {
    this.loadTemplates();
  },
  computed: {
    type() {
      return this.$route.params.type.slice(0, -1) || "template";
    },
    table_title() {
      return `Email ${this.type}s`;
    },
  },
  methods: {
    loadTemplates() {
      this.loadItems(`api/emails/accessories/${this.type}?paginate=true`, true);
    },
    handleDeleteEmailAccesory() {
      this.deleteItem(
        `api/emails/accessories/${this.activeItem.id}`,
        "Email signature deleted",
        this.action_confirm_dialog(false, null),
        null
      );
    },
    redirectEdit(item) {
      this.$router.push({
        name:
          this.type === "template"
            ? "builders-email--template-campaign"
            : "builders-email--signature",
        params: { id: item.id },
        query: {
          redirect_uri: this.$route.fullPath,
        },
      });
    },
    redirectBuild() {
      this.$router.push({
        name:
          this.type === "template"
            ? "builders-email--template-campaign"
            : "builders-email--signature",
        query: {
          redirect_uri: this.$route.fullPath,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
 
</style>