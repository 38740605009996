var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"email-accessories"},[_c('VueTable',{key:_vm.componentKey,attrs:{"items":_vm.items,"headers":_vm.headers,"show-row-actions":true,"show-select":false,"icon":"mdi-alpha-e-box-outline","title":_vm.table_title,"no-more-data":!_vm.has_more_items,"empty-text":"No templates yet","loading":_vm.loading_items},on:{"load-more":_vm.loadMoreItems},scopedSlots:_vm._u([{key:"header-toolbar",fn:function(){return [_c('v-row',{attrs:{"dense":""}},[_c('v-spacer'),_c('v-btn',{staticClass:"text-none",attrs:{"outlined":"","text":""},on:{"click":function($event){return _vm.$router.push({ name: 'campaigns', params: { type: 'email' } })}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-arrow-left-bold-circle-outline")]),_vm._v(" Back ")],1),_c('v-btn',{staticClass:"text-none ml-1",attrs:{"dark":"","color":"primary","disabled":_vm.btnloading},on:{"click":_vm.redirectBuild}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus-circle-outline")]),_vm._v(" New ")],1),_c('v-btn',{staticClass:"text-none ml-1",attrs:{"outlined":"","text":""},on:{"click":function($event){return _vm.$router.push({
              name: 'email-accessories',
              params: {
                type: _vm.type === 'template' ? 'signatures' : 'templates',
              },
            })}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-arrow-right-bold-circle-outline")]),_vm._v(" Email "+_vm._s(_vm._f("ucwords")(_vm.type))+"s ")],1)],1)]},proxy:true},{key:"row-slot",fn:function(ref){
            var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm._f("ucwords")(item.name)))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(_vm._f("ucwords")(item.type)))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(_vm._f("format")(item.created_at,"lll")))]),_c('ActionDropdown',{attrs:{"item":item,"has-edit":true,"has-delete":true,"has-view":true},on:{"view":function($event){return _vm.action_view_dialog(true, item)},"delete":function($event){return _vm.action_confirm_dialog(true, item)},"edit":function($event){return _vm.redirectEdit(item)}}})]}}])}),_c('DeleteDialog',{attrs:{"open-dialog":_vm.confirm_dialog,"title":("Delete Email " + _vm.type),"text-content":("Are you sure you want to delete this email " + _vm.type + "?"),"delete-button-text":("Yes! Delete Email " + _vm.type)},on:{"update:openDialog":function($event){_vm.confirm_dialog=$event},"update:open-dialog":function($event){_vm.confirm_dialog=$event},"delete":_vm.handleDeleteEmailAccesory}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }